import React from "react";
import theme from "theme";
import { Theme, Box, Text, Button, Strong, Span, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox7" color="white" />
			<Override slot="linkBox15" color="--accentOrange" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride1" overflow-x="visible" />
			<Override slot="button" type="link" href="/memory-crystal">
				Order 5D Memory Crystal™
			</Override>
			<Override slot="linkBox14" display="none" />
			<Override slot="text" font="600 27px Epilogue" />
			<Override slot="image" height="50px" />
			<Override slot="text8" font="20px/24px Epilogue">
				Company
			</Override>
			<Override slot="text15" font="20px/24px Epilogue" />
			<Override slot="text17" font="20px/24px Epilogue" sm-font="--bodyText17Medium">
				Contact
			</Override>
			<Override slot="linkBox8" href="/data-storage-innovation" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			padding="90px 190px 120px 190px"
			md-padding="60px 16px 24px 16px"
			overflow-x="hidden"
			overflow-y="hidden"
			md-width="100%"
		>
			<Box
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="48px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="center"
					justify-content="center"
					grid-row-gap="64px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						align-items="center"
						display="flex"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						md-width="100%"
						grid-gap="16px"
					>
						<Text
							margin="0 0 0 0"
							text-align="center"
							word-wrap="break-word"
							font="--headingH0"
							sm-font="normal 700 30px/32px Epilogue"
							md-width="100%"
						>
							Let's Talk
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							text-align="center"
							font="--bodyText21Regular"
							max-width="747px"
							sm-font="--bodyText17Regular"
							md-width="100%"
						>
							Get in touch with us for any inquiries, collaborations, or feedback. We're here to answer your questions and explore how our cutting-edge technologies can meet your needs. Let's connect and innovate together.
						</Text>
					</Box>
					<Components.MakeForm
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
						md-width="100%"
						max-width="600px"
						sm-max-width="100%"
						action="https://hook.us2.make.com/vmpi9qw5hahp757juwr9sws9xf0n4k2u"
						method="post"
						md-max-width="100%"
					>
						<Override
							slot="Form"
							width="600px"
							display="flex"
							flex-direction="column"
							flex-wrap="no-wrap"
							grid-row-gap="16px"
							md-width="100%"
						/>
						{"      "}
						<Components.MfInput
							name="name"
							autocomplete="name"
							type="text"
							required
							placeholder="Name"
							autoComplete
						>
							<Override
								slot="Input"
								display="inline-flex"
								placeholder-opacity={0.4}
								placeholder-color="#080808"
								placeholder-font="400 17px Epilogue"
								placeholder-line-height="26px"
								placeholder-word-wrap="break-word"
								width="100%"
								padding-left="24px"
								padding-right="24px"
								padding-top="16px"
								padding-bottom="16px"
								border-radius="8px"
								overflow="hidden"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="10px"
								md-width="100%"
								border-color="#B1B1B1"
								border-width="1px"
								border-style="solid"
								focus-border-color=" #B1B1B1"
								background="none"
								sm-width="100%"
							/>
						</Components.MfInput>
						<Components.MfInput
							name="email"
							autocomplete="email"
							type="email"
							required
							placeholder="Email"
							autoComplete
						>
							<Override
								slot="Input"
								display="inline-flex"
								placeholder-opacity={0.4}
								placeholder-color="#080808"
								placeholder-font="400 17px Epilogue"
								placeholder-line-height="26px"
								placeholder-word-wrap="break-word"
								width="100%"
								padding-left="24px"
								padding-right="24px"
								padding-top="16px"
								padding-bottom="16px"
								border-radius="8px"
								overflow="hidden"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="10px"
								md-width="100%"
								border-color="#B1B1B1"
								border-width="1px"
								border-style="solid"
								focus-border-color=" #B1B1B1"
								background="none"
								sm-width="100%"
							/>
						</Components.MfInput>
						<Components.MfInput
							placeholder="Company (Optional)"
							name="company"
							autocomplete="organization"
							type="text"
							required={false}
							autoComplete
						>
							<Override
								slot="Input"
								display="inline-flex"
								placeholder-opacity={0.4}
								placeholder-color="#080808"
								placeholder-font="400 17px Epilogue"
								placeholder-line-height="26px"
								placeholder-word-wrap="break-word"
								width="100%"
								padding-left="24px"
								padding-right="24px"
								padding-top="16px"
								padding-bottom="16px"
								border-radius="8px"
								overflow="hidden"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="10px"
								md-width="100%"
								border-color="#B1B1B1"
								border-width="1px"
								border-style="solid"
								focus-border-color=" #B1B1B1"
								background="none"
							/>
						</Components.MfInput>
						<Components.MfTextarea
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							sm-width="100%"
							placeholder="Message"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							required
							as="textarea"
							background="none"
							width="100%"
							resize="vertical"
							min-height="200px"
							name="message"
							autocomplete="off"
							type="text"
						/>
						{"      "}
						<Button
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							background="#EB1E1E"
							border-radius="8px"
							grid-gap="10px"
							color="white"
							font="600 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							text-align="center"
							type="submit"
						>
							Send Message
						</Button>
						{"    "}
					</Components.MakeForm>
					<Box
						align-items="center"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						flex-wrap="no-wrap"
						grid-row-gap="2px"
					>
						<Text margin="0px 0px 0px 0px" font="--bodyText17Medium" text-align="center">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								SPhotonix Inc
							</Strong>
							<br />
							Suite 208, 254 Chapman Rd
							<br />
							Newark, DE 19702
							<br />
							United States
						</Text>
						<Span font="600 17px/26px Epilogue" color="--accentRed">
							info@sphotonix.com
						</Span>
					</Box>
				</Box>
			</Box>
		</Box>
		<Box overflow-x="hidden" overflow-y="hidden">
			<Image
				display="block"
				width="100%"
				src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11:59:50.117Z"
				md-max-width="100%"
				md-max-height="100%"
				srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/map.webp?v=2025-01-11T11%3A59%3A50.117Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
			/>
		</Box>
		<Box background="white">
			<Box
				width="100%"
				padding-top="100px"
				padding-bottom="24px"
				padding-left="190px"
				padding-right="190px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="140px"
				display="flex"
				sm-display="flex"
				sm-padding="24px 16px 24px 16px"
				sm-height="min-content"
				sm-grid-row-gap="64px"
				quarkly-title="Footer"
				grid-row-gap="0px"
				grid-column-gap="0px"
				padding="0px 190px 24px 190px"
				md-padding="0px 16px 24px 16px"
				lg-padding="0px 16px 24px 16px"
			>
				<Box
					align-self="stretch"
					height="51px"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="flex"
					sm-display="flex"
					sm-grid-row-gap="32px"
					sm-height="auto"
				>
					<Box align-self="stretch" height="1px" width="100%" />
					<Components.Footer />
				</Box>
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});