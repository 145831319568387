import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box } from "@quarkly/widgets";
const defaultProps = {
	"align-self": "stretch",
	"justify-content": "space-between",
	"align-items": "center",
	"grid-gap": "32px",
	"display": "flex",
	"md-width": "100%",
	"md-height": "fit-content",
	"md-display": "flex",
	"md-flex-direction": "row",
	"md-flex-wrap": "wrap",
	"md-align-content": "space-around",
	"md-align-items": "center",
	"md-justify-content": "space-between",
	"flex-direction": "row",
	"md-grid-row-gap": "16px"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"children": "©2025 SPhotonix Inc. All rights reserved."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "no-wrap",
			"align-items": "center",
			"justify-content": "center",
			"grid-column-gap": "32px",
			"md-order": "-1"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"children": <Link href="/privacy-policy" color="inherit" text-decoration-line="initial">
				Privacy Policy
			</Link>
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"children": "Cookies"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		{"      "}
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Text {...override("text1")} />
			<Text {...override("text2")} />
		</Box>
		{"                "}
		{children}
	</Box>;
};

Object.assign(Footer, { ...Box,
	defaultProps,
	overrides
});
export default Footer;